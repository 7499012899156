/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { useGetOrderBySellerIdMutation } from "../../slices/orderApiSlice";

const Shop = () => {
  const { userInformation } = useSelector((state) => state?.auth);
  const sellerId = userInformation && userInformation?.sellerID;
  const [getOrder, { data, isLoading, isError }] =
    useGetOrderBySellerIdMutation();
  useEffect(() => {
    const getData = async () => {
      await getOrder({
        entity_id: sellerId,
      });
    };
    getData();
  }, [getOrder, sellerId]);
  const orderData = data && data?.orders;

  // State for filters
  const [statusFilter, setStatusFilter] = useState("");
  const [dateFilter, setDateFilter] = useState("");

  // Filtered orders based on status and date
  const filteredOrders = orderData?.filter((order) => {
    return (
      (!statusFilter || order?.status === statusFilter) &&
      (!dateFilter || order?.date === dateFilter)
    );
  });

  return (
    <Container className="mt-5 mb-5">
      {/* Shop Info and Management Buttons */}
      <Card
        className="shadow-sm p-4 mb-5"
        style={{
          borderRadius: "18px",
          backgroundColor: "#ffffff",
          border: "none",
          boxShadow: "0 8px 20px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Row className="align-items-center">
          <Col xs={12} md={6} className="text-center text-md-start">
            <h1
              style={{
                fontWeight: "700",
                fontSize: "1.5rem",
                color: "#333",
                marginBottom: "20px",
              }}
            >
              {"My Shop"}
            </h1>
          </Col>
          <Col xs={12} md={6} className="text-center text-md-end">
            <Link
              to="/shop/products"
              style={{ textDecoration: "none", marginRight: "10px" }}
            >
              <Button
                variant="outline-success"
                className="mb-3"
                style={{
                  padding: "10px 20px",
                  borderRadius: "30px",
                  fontSize: "1rem",
                  borderColor: "#20c997",
                  boxShadow: "0 4px 12px rgba(32, 201, 151, 0.3)",
                  marginRight: "10px",
                }}
              >
                Manage Inventory
              </Button>
            </Link>
            {/* <Link to="/shop/update" style={{ textDecoration: "none" }}>
              <Button
                variant="outline-dark"
                className="mb-3"
                style={{
                  padding: "10px 20px",
                  borderRadius: "30px",
                  fontSize: "1rem",
                  borderColor: "#343a40",
                  boxShadow: "0 4px 12px rgba(52, 58, 64, 0.2)",
                }}
              >
                Update Shop
              </Button>
            </Link> */}
          </Col>
        </Row>
      </Card>

      {/* Filter Section */}

      {isLoading ? (
        <Loader />
      ) : isError ? (
        <Message variant={"danger"}>something went wrong</Message>
      ) : (
        <>
          {filteredOrders?.length > 0 ? (
            filteredOrders?.map((order) => (
              <Card
                key={order.id}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "20px",
                  border: "1px solid #e0e0e0",
                  backgroundColor: "white",
                  borderRadius: "8px",
                  transition: "transform 0.3s ease-in-out",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  cursor: "pointer",
                  overflow: "hidden",
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.transform = "scale(1.02)")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.transform = "scale(1)")
                }
              >
                <div
                  style={{
                    flex: "1",
                    padding: "10px 20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <div>
                    {order?.quantity && (
                      <p className="order_detail_p">
                        Quantity: {order?.quantity}
                      </p>
                    )}
                    <p className="order_detail_p">
                      Overall Price: ₹{order?.total_price}
                    </p>
                    {order?.buyerPhone && (
                      <p className="order_detail_p">
                        Buyer Phone: {order?.buyerPhone}
                      </p>
                    )}
                    <p className="order_detail_p">
                      Payment Mode: {order?.mode_of_payment || "COD"}
                    </p>
                    <p className="order_detail_p">
                      Payment Mode: {order?.shipping_address}
                    </p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingTop: "8px",
                      borderTop: "1px solid #eee",
                    }}
                  >
                    <Button
                      as={Link}
                      to={`/shop/order/${order?.order_id}`}
                      variant="success"
                      style={{
                        backgroundColor: "#28a745",
                        borderColor: "#28a745",
                        padding: "5px 12px",
                        fontSize: "0.85rem",
                      }}
                    >
                      View Details
                    </Button>
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "bold",
                        fontSize: "0.9rem",
                        color: order?.status === "Shipped" ? "green" : "orange",
                      }}
                    >
                      {order?.status}
                    </p>
                  </div>
                </div>
              </Card>
            ))
          ) : (
            <p className="text-center">You have no orders yet.</p>
          )}
        </>
      )}
    </Container>
  );
};

export default Shop;
